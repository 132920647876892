/**
 * The root paths for the web app.
 * if you're adding a new root path that is not pointing to resources(e.g. thread/group/article),
 * please add it to the ignorePaths array in resourcePath.ts
 */
export enum UrlRootPath {
  achievements = 'achievements',
  articles = 'articles',
  authredirect = 'authredirect',
  configure = 'configure',
  curatedThreadList = 'curated-thread-list',
  discoverGroups = 'discover-groups',
  events = 'events',
  feed = 'feed',
  groups = 'groups',
  campaigns = 'campaigns',
  logout = 'logout',
  inbox = 'inbox',
  campaignsDashboard = 'campaigns-dashboard',
  campaignsHub = 'campaigns-hub',
  questions = 'questions',
  search = 'search',
  storylines = 'storylines',
  teamsMeeting = 'teams-meeting',
  threads = 'threads',
  topics = 'topics',
  topicsNetworkQuestion = 'topics-network-question',
  networkThemeFeed = 'network-themes',
  usagePolicy = 'usage-policy',
  users = 'users',
  userSettings = 'user-settings',
  org = 'org',
  network = 'network',
  admin = 'admin',
  leaderDashboard = 'leader-dashboard',
  leadershipCorner = 'leadership-corner',
  leaders = 'leaders',
  askMeAnything = 'ama',
  insights = 'insights',
  mediaPost = 'media-post',
  communicationsDashboard = 'communications-dashboard',
}

export const networkSchemaRootPaths = [UrlRootPath.org, UrlRootPath.network];
